import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


interface UploadedDocument {
  created_at: string,
  update_at: string,
  account_id: string;
  document_type: string;
  id: string;
  link: string;
  rejection_reason: string;
  status: string;
}
@Component({
  selector: 'app-input-file',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-file.component.html',
  styleUrl: './input-file.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent {

  @Input() inputLabel!: string;
  @Input({ required: true }) inputId!: string;
  @Output() uploadedFileEvent = new EventEmitter<FileList | null>();
  @Input() acceptedFileTypes: string[] = [];
  @Input() maxFileSize!: number; // in KB
  @Input() uploadedDocumentList: UploadedDocument[] = [];

  // Inputs for Tracking Upload Progress
  @Input() uploadProgress: number = 0;
  @Input() isUploading: boolean = false;
  @Input() uploadFailed: boolean = false;
  @Output() resetUploadEvent = new EventEmitter<void>();

  toast = inject(ToastrService);
  change = inject(ChangeDetectorRef);
  fileName!: string;
  fileSize!: string;
  isFileUploaded: boolean = false;

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (!target.files) {
      return;
    }

    let numberOfFiles = target.files.length;
    this.isFileUploaded = true;
    if (numberOfFiles === 1) {
      this.fileName = target.files[0].name;
      this.fileSize = target.files[0].size / 1000 + 'KB';
    } else if (numberOfFiles > 1) {
      this.fileName = numberOfFiles + ' files uploaded';
    }

    // if no file types are specified, then accept all file types
    if (this.acceptedFileTypes.length === 0) {
      this.uploadedFileEvent.emit(target.files);
      return;
    }

    // if maxFileSize is not specified, then accept all file sizes
    if (this.maxFileSize === undefined) {
      this.uploadedFileEvent.emit(target.files);
      return;
    }

    // check file size
    if (target.files[0].size > this.maxFileSize * 1000) {
      setTimeout(() => {
        this.toast.error(
          `File size is too large. Maximum size is ${this.maxFileSize.toLocaleString('en')}KB`
        );
        this.deleteUploadedFile();
      }, 0);

      return;
    }

    // check file type
    const fileType = target.files[0].type;
    if (!this.acceptedFileTypes.includes(fileType)) {
      setTimeout(() => {
        this.toast.error(
          `File type is not supported. Only ${this.acceptedFileTypes.join(
            ', '
          )} files are supported`
        );

        this.deleteUploadedFile();
      }, 0);
      return;
    }

    this.uploadedFileEvent.emit(target.files);
  }



  deleteUploadedFile(): void {
    this.isFileUploaded = false;
    this.resetUploadEvent.emit();
    this.uploadedFileEvent.emit(null);
  }
}
